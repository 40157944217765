<template>
  <div id="crewire-main">
    <div :class="$route.meta.layout == 'default' ? 'default' : ''" class="w-100 d-flex">
      <MainMenu v-if="$route.meta.layout == 'default'" />
      <div class="flex-fill d-flex flex-column main-page">
        <MainHeader v-if="$route.meta.layout == 'default'" />
        <div style="overflow-y: scroll" class="flex-fill hide-scrollbar ">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <OverlayLoader v-show="isLoading" />
  </div>
</template>

<script>

import OverlayLoader from "@/components/base/overlay/OverlayLoader.vue";
import MainMenu from "@/components/base/MainMenu";
import MainHeader from "@/components/base/MainHeader";
//import { socket } from "@/socket";
import { mapMutations } from 'vuex'
export default {
  name: 'App',
  components: { MainMenu, MainHeader, OverlayLoader },

  computed: {
    isLoading: {
      get() {
        return this.$store.state.isLoading;
      }
    },
  },

  mounted() {
    // this.connect();
  }, created() {
    this.setSize()
  },

  methods: {
    ...mapMutations([
      "setHeight", "setWidth"
    ]),
    setSize() {
      window.addEventListener("resize", this.calculateSize);
      this.calculateSize()
    }, calculateSize() {
      let w = document.documentElement.clientWidth;
      this.width = w
      let h = document.documentElement.clientHeight;
      this.height = h
      this.setHeight(h)
      this.setWidth(w)
    },
    connect() {
      //  socket.connect();
    },
  }

}

</script>

<style scoped>
.main-page {
  width: calc(100% - 276px);
  height: 100vh;
  overflow: auto;
  /* padding: ; */
}
</style>