import Vuex from "vuex";

import App from '../App.vue';
import { createApp } from 'vue';

const app = createApp(App);

app.use(Vuex);

export default new Vuex.Store({
      state: {
            isLoading: '',
            accessToken: '',
            stateMenu:false,width:0,height:0,
            user:{},backTitle:""

      },
      getters: {
            getBackTitle(state){
                  return state.backTitle;
            },
            getUser(state){
                  console.log('getUser: ', state.user);
                  return '';
            },
            getHeight(state){
                  //  alert("llllllll")
                  return state.height;
            },
            getWidth(state){
                  //  alert("llllllll")
                  return state.width;
            },
            getStateMenu(state){
                  //  alert("llllllll")
                  return state.stateMenu;
            },
      },
      mutations: {
            mutationer(state, payload) {
                  let keys = Object.keys(payload);
                  for (let item of keys) {
                        state[item] = payload[item]
                  }
            },
            setStateMenu(state,payload) {
                  state.stateMenu = payload;
            },setHeight(state,payload) {
            state.height = payload;
            },setWidth(state,payload) {
            state.width = payload;
            },
            setUser(state,payload) {
                  console.log('payload', payload);
                  state.user = payload;
            },  setBackTitle(state){
                  return state.backTitle;
            },
      }
});
