import App from './App.vue';
import router from "@/router";
import { createApp } from 'vue';
import mitt from 'mitt';
import store from "@/store";
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css';

const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.component('v-select', vSelect)
app.use(router);
app.use(store);

app.mount('#app');
