import { createRouter, createWebHistory } from "vue-router";
import store from '@/store'

function lazyLoad(view) {
      return () => import(`../views/${view}.vue`)
}

const routes = [
      {
            path: "/:catchAll(.*)",
            name: "404",
            meta: { layout: "full", title: "Page introuvable" },
            component: lazyLoad('404'),
      },
      {
            path: '/',
            name: 'homePage',
            component: lazyLoad('home/Home'),
            meta: { layout: "default", title: "Home" , requiresAuth: true },
            props: true,
      },
      {
            path: '/login',
            name: 'login',
            component: lazyLoad('auth/Login'),
            meta: { layout: "full", title: "Login" , requiresAuth: false },
            props: true,
      },
      {
            path: '/forget-password',
            name: 'forgetPassword',
            component: lazyLoad('auth/ForgetPasswordPage'),
            meta: { layout: "full", title: "ForgetPasswordPage" , requiresAuth: false },
            props: true,
      },
      {
            path: '/users',
            name: 'usersList',
            component: lazyLoad('users/Users'),
            meta: { layout: "default", title: "List Of Users" , requiresAuth: true },
            props: true,
      },
      {
            path: '/users/user-detail',
            name: 'userDetail',
            component: lazyLoad('users/userDetail/UserDetail'),
            meta: { layout: "default", title: "User Detail" , requiresAuth: true },
            props: true,
      },
      {
            path: '/daily',
            name: 'dailyPage',
            component: lazyLoad('daily/Daily'),
            meta: { layout: "default", title: "Daily Performance" , requiresAuth: false },
            props: true,
      },
      {
            path: '/training',
            name: 'trainingPage',
            component: lazyLoad('training/Training'),
            meta: { layout: "default", title: "Training Modules" , requiresAuth: true },
            props: true,
      },
];

const router = new createRouter({
      history: createWebHistory(),
      routes,
      linkActiveClass: 'active-link',
      linkExactActiveClass: 'exact-active-link',
});

router.beforeEach((toRoute, fromRoute, next) => {
      window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Home';
      if (toRoute.matched.some(record => record.meta.requiresAuth)) {
            // this route requires auth, check if logged in
            // if not, redirect to login page.
            if (!store.getters.accessToken && !localStorage.getItem('accessToken') && !toRoute.query.token) {
              next({ name: 'login' })
            } else {
              next(); // go to wherever I'm going
            }
          } else {
            next(); // does not require auth, make sure to always call next()!
      }
      next();
})

export default router;
