<template>
  <div class=" w-100">
    <div class="w-100 position-relative">
      <div class="w-100  ">
        <div class="w-100 header  justify-content-between align-items-center d-flex flex-row flex-wrap">
          <div class="left-header d-flex align-items-center">
            <img @click="showMenue()" src="../../assets/images/menu/menu.png"
              class=" menu-icon pointer d-md-none d-block">
            <router-link :to="{ name: 'usersList' }" v-if="$route.meta.title == 'User Detail'"
              class="h-100  ml-md-0 ml-4 d-flex align-items-center gray text-gray selected-title">
              All Users
              <img src="@/assets/images/Arrow-Right-Circle.svg" class="mx-3" />
            </router-link>
            <span :style="{ opacity: (this.$route.path == '/users') ? 0.3 : 1 }"
              v-if="$route.meta.title == 'User Detail'"
              class="h-100  ml-md-0 ml-4 d-flex align-items-center selected-title">{{ userData || $route.meta.title }}</span>
            <span v-else :style="{ opacity: (this.$route.path == '/users') ? 0.3 : 1 }"
              class="h-100  ml-md-0 ml-4 d-flex align-items-center selected-title">{{ $route.meta.title }}</span>
          </div>
          <div class="pic-box d-flex align-items-center justify-content-center">
            <img v-if="getUser() && getUser().avatar" class="profile-pic" :src="getUser().avatar">
            <img v-else class="profile-pic" src="../../assets/images/user-default.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: 'MainHeader',
  data() {
    return {
      showPopup: false,
    }
  },
  computed: {
    userData() {
      return this.$route.query.name ? this.$route.query.name : this.$store.state.user ? this.$store.state.user.full_name : ''
    }
  },
  methods: {
    ...mapMutations([

      "setStateMenu",
    ]),
    ...mapGetters([
      "getStateMenu", "getUser"
    ]),
    logOut() {
      this.setParam("Token", null)
      this.goToOtherPage("/login")
    },
    specialBack() {
      this.setbackTitle("")

      this.$root.$emit('hideDetails', false)
    },
    goToOtherPage(path) {
      this.showPopup = false
      this.$router.push({ path: path })
    },
    showMenue() {
      if (!this.getStateMenu()) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
      this.setStateMenu(!this.getStateMenu())
    }
  },
}
</script>

<style lang="scss" scoped>
.selected-title.text-gray {
  color: #00000030 !important;
}

.header {
  padding: 35px 35px 20px 35px;

}

.menu-icon {
  height: changeScreen(40) *1rem;
}

.pic-box {
  height: changeScreen(60) *1rem;
  width: changeScreen(60) *1rem;
  background: rgba(168, 185, 240, 0.3);
  border-radius: changeScreen(18) *1rem;
}

.profile-pic {
  height: changeScreen(48) *1rem;

  border-radius: changeScreen(16) *1rem;
  box-shadow: 0 changeScreen(2) *1rem changeScreen(4) *1rem rgba(0, 0, 0, 0.16);
}

.logo {
  height: changeScreen(77) *1rem;
}

.left-header {
  font-family: H1;
  font-size: changeScreen(16) *1rem;
  color: #6B7173;
}

@media screen and (max-width: 768px) {
  //.pl-menu{
  //  padding-left: 0px;
  //}
}

.popup {
  width: changeScreen(248) *1rem;
  border-radius: changeScreen(16) *1rem;
  background: #FFFFFF;
  box-shadow: 0 changeScreen(8) *1rem changeScreen(16) *1rem rgba(34, 96, 171, 0.3);
  padding: changeScreen(20) *1rem;
  top: changeScreen(135) *1rem;
  right: changeScreen(64) *1rem;
}

.popup-title {
  font-family: H1;
  color: #2260AB;
  font-size: changeScreen(16) *1rem;
}

.popup-sub-title {
  font-family: H1;
  color: #6B7173;
  font-size: changeScreen(14) *1rem;
}

.popup-footer {
  color: #E85A15;
  font-family: H1SemiBold;
  font-size: changeScreen(16) *1rem;
}

.popup-items {
  font-family: H1;
  font-size: changeScreen(16) *1rem;
  color: #333333;
}

.icon {
  height: changeScreen(22) *1rem;
}

.seprator {
  height: 1px;
  background: #F2F2F2;
}

.next-icon {
  width: changeScreen(24) *1rem;
  height: changeScreen(24) *1rem;


}

.selected-title {
  font-family: TahomaBold;
  font-size: changeScreen(23) *1rem;
  color: #000000;
}
</style>
