<template>
  <div style="z-index: 9;">
    <div style="z-index: 9" class="d-md-none d-block">
      <div class="h-100   position-fixed d-flex flex-row" :class="getStateMenu() ? 'w-100' : 'w-0'"
        style="z-index: 99999999999999999 ;top: 0px;left: 0px">
        <div style="z-index: 99999999999;width: 17rem" :class="getStateMenu() ? 'menuDeAnimate' : 'menuAnimate'"
          class="overflow-hidden position-relative d-flex flex-column  h-100 main-menu ">
          <div style="overflow-y: scroll " class="d-flex flex-column h-100 hide-scrollbar position-relative left-box">
            <div class="w-100 flex-fill">
              <div class="w-100">
              </div>
              <div class="w-100 pb-5 d-flex">
                <div class="d-flex flex-column pl-5 mt-5 w-100  justify-content-center">
                  <div class="profile-title mt-4">
                    <span>Hello Dear Admin</span>
                    <span v-if="getUser().first_name"> {{ getUser().first_name }}</span>
                    <span>,</span>
                  </div>
                  <div class="profile-sub-title mt-3">Welcome Back!</div>
                </div>
              </div>
              <div class="w-100 mt-5 pb-5">
                <div @click="changePage(index, item.route)" v-for="(item, index) in menuData" :key="index"
                  :class="index == selectedIndex ? 'selected-items' : ''"
                  class="mt-5 pointer items align-items-center pl-5 d-flex w-100">
                  <img class="icon" :src="getImgUrl(item.selectedIcon)">
                  <div class="h-100 d-flex align-items-center pl-3">{{ item.name }}</div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center align-items-center pointer footer w-100 text-center">
              <div class="d-flex" @click="logout()">
                <img src="../../assets/images/menu/logout.svg">
                <span class="pl-3 pt-1">EXIT</span>
              </div>
            </div>
          </div>

        </div>

        <div @click="showMenue" class="w-100 h-100 position-absolute menu">

        </div>
      </div>
    </div>
    <div style="z-index: 9;" class=" d-md-block d-none">
      <div style="overflow-y: scroll "
        class="h-menu d-flex flex-column  hide-scrollbar position-relative box-radius left-box">
        <div class="w-100 flex-fill">
          <div class="w-100">
          </div>
          <div class="w-100 pb-5 d-flex">
            <div class="d-flex flex-column pl-5 mt-5 w-100  justify-content-center">
              <div class="profile-title mt-4">
                <span>Hello Dear Admin</span>
                <span v-if="getUser().first_name"> {{ getUser().first_name }}</span>
                <span>,</span>
              </div>
              <div class="profile-sub-title mt-3">Welcome Back!</div>
            </div>
          </div>
          <div class="w-100 mt-5 pb-5">
            <router-link class="mt-5 pointer items align-items-center pl-5 d-flex w-100" v-for="(item, index) in menuData" :key="index" :to="{path: item.route}">
              <img class="icon" :src="getImgUrl(item.selectedIcon)">
              <div class="h-100 d-flex align-items-center pl-3">{{ item.name }}</div>
            </router-link>
          </div>
        </div>

        <div class="d-flex justify-content-center align-items-center pointer footer w-100 text-center">
          <div class="d-flex" @click="logout()">
            <img class="exit-icon" src="../../assets/images/menu/logout.svg">
            <span class="pl-3">EXIT</span>
          </div>
        </div>
      </div>
    </div>
    <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { findIndexOfObjInArr } from "../../utils/IndexOfObject";

import ConfirmDialogue from './ConfirmDialogue.vue';
import { logout } from "@/services/axios/logout.service";

import { toast } from 'vue3-toastify';
import store from '@/store';

export default {
  name: 'MainMenu',
  components: {
    ConfirmDialogue
  },

  data: () => ({
    selectedIndex: 0,

    ecosystem: [],
    menuData: [
      { route: "/", name: "HOME", icon: "home", selectedIcon: "home-selected" },
      { route: "/users/", name: "USERS", icon: "user", selectedIcon: "user-selected" },
      { route: "/training/", name: "TRAINING MODULES", icon: "train", selectedIcon: "train-selected" },
      { route: "/daily/", name: "DAILY PERFORMANCE", icon: "daily", selectedIcon: "daily-selected" }
    ],

    toastConfig: {
      autoClose: 3000,
      position: toast.POSITION.BOTTOM_LEFT,
      type: toast.TYPE.SUCCESS,
      theme: toast.THEME.COLORED
    },

  }),

  watch: {
    stateMenu: function (val) {
      this.stateMenu = val
    }
  },

  methods: {

    getImgUrl(pet) {
      var images = require.context('../../assets/images/menu/', false, /\.svg/);
      return images('./' + pet + ".svg");
    },

    ...mapMutations([
      "setStateMenu",
    ]),

    ...mapGetters([
      "getStateMenu",
      "getUser",
    ]),

    showMenue() {
      if (!this.getStateMenu()) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
      this.setStateMenu(!this.getStateMenu());
    },

    changeShow() {
      document.body.classList.remove('overflow-hidden')
      this.setStateMenu(false)
    },

    changePage(index, routeName) {
      if (this.$route.path == routeName) {
        return
      }

      this.selectedIndex = index
      this.$router.push({
        path: `${routeName}`
      });
      this.changeShow()
    },

    async logout() {
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Logout User',
        message: 'Are you sure?',
        okButton: 'Yes!',
      })
      if (ok) {
        this.userLogout();
      }
    },

    userLogout() {
      logout().then(res => {
        if (!res) {
          return;
        }

        localStorage.removeItem('accessToken');
        store.commit('mutationer', { accessToken: '' });

        this.$router.push({ name: 'login' });

        toast(res?.data?.message, this.toastConfig);
      }).catch(e => {
        console.log(e)
      })
    },
  },


  mounted() {
    this.selectedIndex = findIndexOfObjInArr(this.menuData, this.$route.path)
  }
}



</script>
<style lang="scss" scoped>
.h-menu {
  height: calc(100vh - 48px);
  margin: 24px 0 24px 24px;
  .active-link {
    opacity: 1 !important;
  border-left: solid #032344 4px;
  border-radius: 0px 4px 4px 0px;
  }
}

.header-items {
  color: #2A3C46;
  margin-top: 2rem;
}

.selected {
  font-weight: bold;
}

.selected .circle {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.25rem;
  background: black;
  margin: 0 auto;
  margin-top: 0.5rem
}

.menu {
  font-family: SourceSansPro-Light;
  color: black;
  z-index: 999;
  top: 0px;
  opacity: 0.46;
  background-color: rgb(33, 33, 33);
  border-color: rgb(33, 33, 33);
}

/*.slide-fade-enter-active {*/
/*  transition: all .3s ease;*/
/*}*/
/*.slide-fade-leave-active {*/
/*  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);*/
/*}*/
/*.slide-fade-enter, .slide-fade-leave-to*/
/*  !* .slide-fade-leave-active below version 2.1.8 *! {*/
/*  transform: translateX(1000px);*/
/*  opacity: 0;*/
/*}*/
.exit-icon {
  height: changeScreen(20) *1rem;
}

.colorWhite {
  color: white;
}

.menuAnimate {
  transform: translateX(-100%);
}

.menuDeAnimate {
  transform: translateX(0%);
}

/*.menuAnimate{*/
/*  -webkit-transition: width 1s ease;*/
/*  -moz-transition: width 1s ease;*/
/*  -ms-transition:width 1s ease;*/
/*  -o-transition: width 1s ease;*/
/*  transition: width 1s ease;*/
/*}*/
.main-menu {
  /*box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%);*/
  background: white;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  will-change: transform;
  transition-property: transform, visibility, width;
}

.box-radius {
  border-radius: changeScreen(48) *1rem;
}

.left-box {
  width: changeScreen(344) *1rem;
  left: 0px;
  top: 0px;
  background: rgba(168, 185, 240, 0.2);
  //padding:changeScreen(54) *1rem  changeScreen(38) *1rem;

  z-index: 99999999999999999;
}

.pic-box {
  min-height: changeScreen(64) *1rem;
  min-width: changeScreen(64) *1rem;
  background: rgba(34, 96, 171, 0.1);
  border-radius: changeScreen(20) *1rem;
}

.profile-pic {

  width: changeScreen(48) *1rem;
  border-radius: changeScreen(16) *1rem;
  box-shadow: 0 changeScreen(2) *1rem changeScreen(4) *1rem rgba(22, 67, 122, 0.26);
}

.profile-title {
  font-family: TahomaBold;
  font-size: changeScreen(26) *1rem;
  color: #0C2242;
}

.profile-sub-title {
  font-family: Tahoma;
  font-size: changeScreen(19) *1rem;
  color: #0C2242;
}

.icon {
  width: changeScreen(24) *1rem;
}

.items {
  font-family: Tahoma;
  font-size: .875rem;
  color: #000000;
  opacity: 0.3;
}

.selected-items {
  opacity: 1 !important;
  border-left: solid #032344 4px;
  border-radius: 0px 4px 4px 0px;
}

.footer {
  min-height: changeScreen(100) *1rem;
  font-family: Tahoma;
  font-size: changeScreen(18) *1rem;
  color: #EA2D24;
  border-top: solid #FFFFFF 1.5px;
}

.menu-icon {
  height: changeScreen(20) *1rem;
}</style>
